let url = "";
let calendarRedirectURL = "";
let isGalaxPayInProd = false;
let politicasPrivacidadeURL = "";
let termosUsoURL = "";

if (process.env.NODE_ENV === 'development') {
	url = "http://localhost:8000";
	calendarRedirectURL = 'http://localhost:8000/api/barbeiro/google/auth';
	politicasPrivacidadeURL = 'http://localhost:3001/politica-de-privacidade';
	termosUsoURL = 'http://localhost:3001/termos-de-uso';
} else if (process.env.REACT_APP_STAGE === 'homolog') {
	url = "https://dev.api.cashbarber.com.br";
	calendarRedirectURL = 'https://dev.api.cashbarber.com.br/api/barbeiro/google/auth';
	politicasPrivacidadeURL = 'https://dev.cashbarber.com.br/politica-de-privacidade';
	termosUsoURL = 'https://dev.cashbarber.com.br/termos-de-uso';
} else {
	url = "https://api.cashbarber.com.br";
	calendarRedirectURL = 'https://api.cashbarber.com.br/api/barbeiro/google/auth';
	politicasPrivacidadeURL = 'https://cashbarber.com.br/politica-de-privacidade';
	termosUsoURL = 'https://cashbarber.com.br/termos-de-uso';
	isGalaxPayInProd = true;
}

export const Constants = {
	url: url,
	baseUrl: `${url}/api/barbeiro`,
	pumpUrl: `${url}/api/pump`,
	calendarRedirectURL: calendarRedirectURL,
	google_client_id: '318754238400-mp9hokoa0r82mgip4nnndfbjr2h72rik.apps.googleusercontent.com',
	isGalaxPayInProd,
	webPushPublicKey: 'BMf5GAN0LPUsaALujaqaM_i29Y0iPHlm3ncvgPYPdKJuielV2iU91qM-Iz0wuh8UXL7KV_jQYZyNzKvekuwpAsk',
	politicasPrivacidadeURL,
	termosUsoURL
};
