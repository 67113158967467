import axios from "axios";
import { Constants } from '../utils/Constants';

export function getDataTable(events) {
	return axios.post(`${Constants.baseUrl}/usuarios/list`, events);
}

export function create(form) {
	return axios.post(`${Constants.baseUrl}/usuarios`, form);
}

export const getPermissions = (data) => {
	return axios.post(`${Constants.baseUrl}/usuarios/permissions`, data);
};

export function get() {
	return axios.get(`${Constants.baseUrl}/perfil`);
}

export function update(form) {
	return axios.post(`${Constants.baseUrl}/perfil`, form);
}

export function destroy(id) {
	return axios.delete(`${Constants.baseUrl}/usuarios/${id}`);
}

export function getSimpleList(data) {
	return axios.post(`${Constants.baseUrl}/usuarios/simpleList`, data ?? null);
}

export function getSimpleListBarbeiro(data) {
	return axios.post(`${Constants.baseUrl}/usuarios/simpleListBarbeiro`, data ?? null);
}

export function getServicosBarbeiroList() {
	return axios.get(`${Constants.baseUrl}/usuarios/servicos/list`);
}

export const registerPush = (form) => {
	return axios.post(`${Constants.baseUrl}/perfil/push`, form);
};

export const removePush = (data) => {
	return axios.post(`${Constants.baseUrl}/perfil/push/delete`, data);
};

export const checkIfPushIsRegistered = (data) => {
	return axios.post(`${Constants.baseUrl}/perfil/push/check`, data);
};