export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Agendamentos",
				root: true,
				icon: "fas fa-calendar-day",
				page: "agendamento",
				bullet: "dot"
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Comissões",
						bullet: "dot",
						page: "relatorio/relatorio01"
					},
				]
			},
			{
				title: "Prévia",
				root: true,
				icon: "fas fa-chart-area",
				page: "previa",
				bullet: "dot"
			},
			{
				title: "Perfil",
				root: true,
				icon: "fas fa-user",
				page: "perfil",
				bullet: "dot"
			}
		]
	}
};
